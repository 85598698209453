<template>
  <company-edit :object="object" />
</template>
<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import ObjectManipulator from '@/components/mixins/ObjectManipulator'
import BodyMixin from '@/components/mixins/Body'
import CompanyEdit from './CompanyEdit.vue'

export default {
  name: 'Company',
  mixins: [
    ObjectManipulator(
      'company' /* IMPORTANT: object type: labor_type, cost_type, quote etc */,
      true /* track changes? */,
      'Company' /* StoreName, object type in title case ie: LaborType, Quote, Client, CostType etc */
    ),
    BodyMixin /* AutoSave */
  ],
  components: {
    CompanyEdit
  },
  setup(props) {
    const store = useStore()
    const object = computed(() =>
      props.object && Object.keys(props.object).length ? props.object : store.state.session.company
    )
    return {
      object
    }
  }
}
</script>
